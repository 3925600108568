body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* common */
  --black: #1E2021;
  --black-dark: #171716;
  --light-black: #636363;

  --white: #FDFDFD;

  --mid-gray: #898989;
  --light-gray: #D6D6D6;
  --mid-light-gray: #EEEEEE;
  --lightest-gray: #F7F7F7;

  --orange: #F99E53;
  --orange-mid: rgba(249, 158, 83, 0.05);
  --dark-orange: #E7812C;
  --orange-light: rgba(249, 158, 83, 0.1);

  --purple: #BF79E0;
  --purple-light: rgba(191, 121, 224, 0.1);

  --blue: #72C7F7;
  --blue-light: rgba(114, 199, 247, 0.1);
  --blue-dark: #1E9FE8;

  --red: #F95D53;

  background-color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

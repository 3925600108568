.StrengthTrainingContent {
    margin: 0 auto;
    width: 320px
}

.StrengthTrainingHeader {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--mid-light-gray);
    margin: 0 auto 8px;
    width: 320px
}

.StrengthTrainingGender {
    display: flex;
    justify-content: center;
}

.StrengthTrainingLabel {
    padding: 4px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--black-dark);
}

.StrengthTrainingDescription {
    margin-top: 20px;
    padding: 6px 12px;
    background: var(--lightest-gray);
    border-radius: 8px;
}

.maxReps {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.maxRepsItemLabel {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: var(--black-dark);
}

.maxRepsBlockLabel {
    margin-top: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: var(--black-dark);
}

.StrengthTrainingObjective {
    margin-top: 12px;
}

.StrengthTrainingExerciseTags {
    margin-top: 12px;
}

.StrengthTrainingExerciseTags .StrengthTrainingExerciseTag {
    margin-right: 6px;
    margin-bottom: 4px;
}

.StrengthTrainingExerciseTag {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid var(--orange-light);
    background: var(--orange-mid);
    display: inline-flex;
    align-items: center;
}

.StrengthTrainingExerciseBlock {
    margin-top: 12px;
}

.StrengthTrainingGetResults {
    margin-top: 36px;
    padding: 11px 12px;
    background: linear-gradient(236.63deg, #C573FF 1.54%, #929DFF 94.71%);
    border-radius: 8px;
    outline: none;
    border: none;
    color: #fff;
    width: 100%;
    max-width: 320px;
    transition: background .25s;
    cursor: pointer;
}

.StrengthTrainingGetResults:hover {
    background: linear-gradient(236.63deg, #bc63fc 1.54%, #7e8bf8 94.71%);
    transition: background .25s;
}
